@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik+Pixels&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@font-face {
  font-family: Sans;
  src: url(./assets/ProductSans-Regular.woff);
}
body {
  margin: 0%;
  font-family: outfit;
  background-color: #f6f6f6;
}

.product::-webkit-scrollbar {
  display: none;
}
.services {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("./assets/2.JPG");
  background-size: cover;
  background-position: center;
}
.code_camp {
  background: linear-gradient(rgba(19, 30, 71, 1), rgba(19, 30, 71, 0.4)),
    url("./assets/code.JPG");
  background-size: cover;
  background-position: center;
}
.code_camp_uk {
  background: linear-gradient(rgba(19, 30, 71, 1), rgba(19, 30, 71, 0.4)),
    url("./assets/newBanner.jpeg");
  background-size: cover;
  background-position: center;
}
.code_camp2 {
  background: linear-gradient(rgba(19, 30, 71, 1), rgba(19, 30, 71, 0.7)),
    url("./assets/code.JPG");
  background-size: cover;
  background-position: center;
}
.button_join {
  background-image: linear-gradient(
    to right,
    #ddff56,
    #b8ea4b,
    #94d540,
    #71bf37,
    #4caa2f,
    #45a63a,
    #40a243,
    #3c9e4b,
    #57ab65,
    #70b87e,
    #89c596,
    #a2d1ae
  );
  transition: background-image 0.5s ease !important;
}

.line {
  width: 100%;
  height: 4rem;
  overflow: hidden;

  padding: 0;
  margin-bottom: 16px;
}
.lineUp {
  animation: 2s anim-lineUp ease-out;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
.typewriter {
  color: #fff;
  font-family: monospace;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 2s steps(30, end), blink-caret 0.5s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

/* >>>>>>>>>>>>>Home>>>>>>>>>>>> */

.appbar {
  background: transparent !important;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in !important ;
  box-shadow: none !important;
}

.appbar_bg {
  background: #010911b7 !important;
  height: 100px;
  backdrop-filter: blur(30px);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in !important ;
}
.banner {
  background: url("./assets/HomeDisplayIndex.jpg");
  background-size: cover;
}
a {
  text-decoration: none;
}
/* a:hover{
font-weight: 600;
} */

.banner_container {
  /* background: url('./assets/pattern.svg'); */

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
/* .back{
  backdrop-filter: brightness(0.19);
} */
.hero_middle {
  /* border: 1px solid #3c9e4b; */
  background: url("./assets/newDisplay.png");
  background-size: cover;
}

.input2_waitlist:focus {
  outline-color: #89c596;
}
.input2_waitlist_black:focus {
  outline-color: #89c596;
}
.input2_waitlist::placeholder {
  font-family: outfit;
  font-size: 13px;
}

/* >>>>>>>>>>GET STARTED >>>>>>>>>>>>*/

.slider-div {
  height: 100px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.drawer {
  background: url("./assets/drawer-back.png") !important;
  background-size: cover !important;
  color: #fff !important;
  background-position: center !important;
  height: 400px !important;
  filter: opacity(0.08);
  background-position-x: right !important;
  background-position-y: -400px;
}

.cert {
  width: 800px;
  height: 600px;
}
@media (max-width: 780px) {
  .cert {
    width: 85%;
    height: 500px;
  }
}

.certWrapper {
  /* padding: 5% 10%; */
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

/* Responsive styles */
@media (max-width: 600px) {
  .cert {
    height: 400px;
    width: 90%; /* Adjust the height for smaller screens */
  }
  .certWrapper {
    /* padding: 5; */
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 460px) {
  .cert {
    height: 250px;
    width: 95%; /* Adjust the height for smaller screens */
  }
  .certWrapper {
    /* padding: 5; */
    display: flex;
    justify-content: center;
  }
}

.circle {
  display: flex;
  justify-content: center;
  /* padding-top: 50%; */
}

.certContainer {
  background-image: linear-gradient(270.4deg, #131e47 17.31%, #338695 99.58%);
  height: 100vh;
}
.welcome {
  text-align: center;
  color: #fff;
}
.welcomediv {
  padding-top: 20px;
}
.wrap {
  width: 100%;
}

.imgWrap {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.textCenter {
  text-align: center;
  color: #fff;
}
.errorAlign {
  align-items: center;
  display: flex;
  justify-content: center;
}
.error {
  display: flex;
  justify-content: center;
}
.errorContainer {
  background-image: linear-gradient(270.4deg, #131e47 17.31%, #338695 99.58%);
  height: 100vh;
  padding-top: 15vh;
}
