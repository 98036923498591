/* Add this to your CSS file or in a style block */
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
  .float-image {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    animation: bounce 3s infinite;
  }
  
  