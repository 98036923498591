@tailwind base;
@tailwind components;
@tailwind utilities;


@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@media (max-width: 600px) {
    .order-xs-1 {
      order: 1;
    }
    .order-xs-2 {
      order: 2;
    }
  }
  
  @media (min-width: 600px) {
    .order-sm-1 {
      order: 1;
    }
    .order-sm-2 {
      order: 2;
    }
  }
  